// Entry point for the build script in your package.json
window.Buffer = window.Buffer || require("buffer").Buffer;
import * as React from 'react'
import { createRoot } from 'react-dom/client';
import CopyToClipboard from './CopyToClipboard';
import DarkModeToggle from './DarkModeToggle';
DarkModeToggle();

import DreamMuseumsLoginApp from './DreamMuseumsLoginApp';
import DreamMuseumsSignatureApp from './DreamMuseumsSignatureApp';
import DreamMuseumsTokenPreviewApp from './DreamMuseumsTokenPreviewApp';
import DreamMuseumsImportApp from './DreamMuseumsImport/DreamMuseumsImportApp';
import DreamMuseumsMarketplaceApp from './DreamMuseumsMarketplaceApp';
import HomeCarousel from './HomeCarousel';

const signatureContainer = document.getElementById('signature');
const loginContainer = document.getElementById('login');
const tokenPreviewContainer = document.getElementById('token-preview');
const marketplaceContainer = document.getElementById('marketplace');
const importNftContainer = document.getElementById('import-nft');
const homeCarouselContainer = document.getElementById('home-carousel');

if (signatureContainer) {
  createRoot(signatureContainer).render(<DreamMuseumsSignatureApp />);
}

// if (loginContainer) {
  // createRoot(loginContainer).render(<DreamMuseumsLoginApp />);
// }

if (tokenPreviewContainer) {
  createRoot(tokenPreviewContainer).render(<DreamMuseumsTokenPreviewApp />);
}

// if (marketplaceContainer) {
//   createRoot(marketplaceContainer).render(<DreamMuseumsMarketplaceApp />);
// }

if (importNftContainer) {
  createRoot(importNftContainer).render(<DreamMuseumsImportApp />);
}

if (homeCarouselContainer) {
  createRoot(homeCarouselContainer).render(<HomeCarousel />);
}

CopyToClipboard();