import * as React from 'react'
import { CenterProvider, Asset, useAsset } from 'nft-react'

const pathnameAsArray = window.location.pathname.split('/')
const contractId = pathnameAsArray[pathnameAsArray.length - 2];
const tokenId = pathnameAsArray[pathnameAsArray.length - 1];

const DreamMuseumsTokenPreviewApp = () => {
  return (
    <CenterProvider
      network="ethereum-mainnet"
      mode="production"
      apiKey="1c6e0321-da1b-4bcf-af03-f577eb4f735f">
        <AssetDetails></AssetDetails>
    </CenterProvider>
  )
}

const AssetDetails = () => {
  const result = useAsset({
    network: 'ethereum-mainnet',
    address: contractId,
    tokenId: tokenId
  })

  if (result && result.name) {
    const existingPreviewNameElement = document.getElementsByClassName('token-preview-name')[0]
    if (existingPreviewNameElement && result.name != tokenId) {
      if (result.name.length > existingPreviewNameElement.textContent.length) {
        existingPreviewNameElement.textContent = result.name
      }
    }
  }

  return (
    <>
      <div className='token-preview-image-medium'>
        <Asset
        address={contractId}
        tokenId={tokenId}
        preset='medium'
        network='ethereum-mainnet'/>
      </div>
    </>
  )
}

export default DreamMuseumsTokenPreviewApp;