import React from 'react';
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

const IMPORT_ITEM = gql`
  mutation ImportItem($contract_id: String!, $token_id: String!, $asset_url: String!, $name: String!) {
    importItem(input: { contractId: $contract_id, tokenId: $token_id, assetUrl: $asset_url, name: $name }) {
      collection {
        id
        name
      }
      errors
    }
  }
`;

const pathnameAsArray = window.location.pathname.split('/')
const contractId = pathnameAsArray[pathnameAsArray.length - 2];
const tokenId = pathnameAsArray[pathnameAsArray.length - 1];

var import_message = 'Import NFT'

function ImportItem() {
  onSubmit = (e, importItem) => {
    e.preventDefault();
    const name = document.getElementsByClassName('token-preview-name')[0].textContent
    const asset_url = document.getElementsByClassName('token-preview-image-medium')[0].firstChild.firstChild.src
    const importParams = { contract_id: contractId, token_id: tokenId, name: name, asset_url: asset_url }
    importItem({ variables: importParams });
  }

  onImportItem = (e, importItem) => {
    import_message = 'NFT Imported ✅'
    document.getElementsByClassName('import-nft-remaining-count')[0].textContent = 'Great work!'
  }

  return (
    <Mutation
      mutation={IMPORT_ITEM}
      update={this.onImportItem}>
      {importItemMutation => (
        <form className="px-8 pt-0 pb-3" onSubmit={e => this.onSubmit(e, importItemMutation)}>
          <button
            className="py-3.5 btn-primary px-3.5"
            type="submit">
            {import_message}
          </button>
        </form>
      )}
    </Mutation>
  );
}
export default ImportItem;