import * as React from 'react'
import { wagmiClient, chains } from './WagmiClient';
import { ethers } from 'ethers'
import { generateNonce } from 'siwe'

import {
  WagmiConfig,
  useAccount,
  useConnect,
  useSignMessage,
} from 'wagmi';

import {
  ConnectButton,
  darkTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

const DreamMuseumsLoginApp = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme({
        accentColorForeground: '#161616',
        accentColor: '#aee7f7',
      })}>
        <DreamMuseumsLogin />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

const DreamMuseumsLogin = () => {
  const { isConnected } = useConnect();

  const { data } = useAccount()

  if (data) {
    publicAddress = data.address;
    let nonce = generateNonce();
    let time = new Date().getTime();
    message = 'Log in as: ' + publicAddress + '\nNonce: ' + nonce + '\nTime: ' + time;
  }

  if (!isConnected) {
    return <ConnectButton label="Log in with wallet" showBalance={false} />;
  }

  return (
    <>
      <div className="my-1">
        <ConnectButton label="Log in with wallet" showBalance={false} />
      </div>
      <div className="my-1">
        <SignAddressAndLogin />
      </div>
    </>
  );
};

const SignAddressAndLogin = () => {

  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
    message: message,
    onSuccess(data) {
      signature = data;
      publicAddress = ethers.utils.verifyMessage(message, signature)
      sendSignedMessageForLogin(message, signature, publicAddress);
    },
  })

  return (
    <div>
      <button disabled={isLoading} onClick={() => signMessage()} className="btn-primary hover:scale-105 hover">
        Sign & log in
      </button>
      {isError && <div>Error signing message</div>}
    </div>
  )
}

function sendSignedMessageForLogin(message, signature, publicAddress) {
  return fetch('/api/sign_in', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message, signature, public_address: publicAddress }),
  }).then(response => response.json())
    .then(data => {
      if (data.success) {
        window.location.href = '/settings';
      } else {
        let thisPage = new URL(window.location.href);
        thisPage.searchParams.append('error', data.errors[0]);
        window.location.href = thisPage;
      }
    })
}

export default DreamMuseumsLoginApp;