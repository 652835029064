import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import {
  connectorsForWallets,
  wallet
} from '@rainbow-me/rainbowkit';

import {
  chain,
  configureChains,
  createClient,
} from 'wagmi';

export const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    alchemyProvider({ alchemyId: 'UY2AXENmr0UyBjHBFgJHG8e5Htvaxogi' }),
    publicProvider()
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Browser',
    wallets: [
      wallet.metaMask({ chains }),
    ],
  },
  {
    groupName: 'Mobile',
    wallets: [
      wallet.rainbow({ chains }),
    ]
  },
  {
    groupName: 'Other',
    wallets: [
      wallet.walletConnect({ chains }),
    ]
  },
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})