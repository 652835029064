import * as React from 'react'
import {
  ReservoirKitProvider,
  darkTheme,
} from '@reservoir0x/reservoir-kit-ui'
import { WagmiConfig, useConnect } from 'wagmi'
import { wagmiClient, chains } from './WagmiClient';
import { BuyModal } from '@reservoir0x/reservoir-kit-ui'
import { ConnectButton, RainbowKitProvider, darkTheme as rainbowDarkTheme } from '@rainbow-me/rainbowkit';

const pathnameAsArray = window.location.pathname.split('/')
const contractId = pathnameAsArray[pathnameAsArray.length - 2];
const tokenId = pathnameAsArray[pathnameAsArray.length - 1];

const reservoirKitTheme = darkTheme({
  headlineFont: "Sans Serif",
  font: "Serif",
  primaryColor: "#323aa8",
  primaryHoverColor: "#252ea5",
})

const DreamMuseumsMarketplaceApp = () => {
  return (
    <>
      <ReservoirKitProvider
        options={{
          apiBase: 'https://api.reservoir.tools',
        }}
        theme={reservoirKitTheme}
      >
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains} theme={rainbowDarkTheme({
            accentColorForeground: '#161616',
            accentColor: '#aee7f7',
          })}>
            <BuyOrConnect />
          </RainbowKitProvider>
        </WagmiConfig>
      </ReservoirKitProvider>
    </>
  )
};

const BuyOrConnect = () => {
  const { isConnected } = useConnect();

  if (!isConnected) {
    return <ConnectButton label="Connect Wallet to Buy (Beta)" showBalance={false} />;
  }

  return (
    <BuyModal
      trigger={
        <button className='btn-primary'>
          Buy Token (Beta)
        </button>
      }
      collectionId={contractId}
      tokenId={tokenId}
      referrerFee="0.01"
      onComplete={() => console.log('Purchase Complete')}
    />
  )
}

export default DreamMuseumsMarketplaceApp;