import React, { Component } from 'react';
import ImportItem from './ImportItem';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const origin = window.location.origin;

const link = createHttpLink({
  uri: origin + '/graphql',
  credentials: 'same-origin'
});

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

class DreamMuseumsImportApp extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <div className="container mx-auto px-4">
          <ImportItem />
        </div>
      </ApolloProvider>
    );
  }
}
export default DreamMuseumsImportApp;
