import * as React from 'react'
import { wagmiClient, chains } from './WagmiClient';
import { ethers } from 'ethers'

import {
  WagmiConfig,
  useAccount,
  useConnect,
  useSignMessage,
} from 'wagmi';

import {
  ConnectButton,
  darkTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

const DreamMuseumsSignatureApp = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme({
        accentColorForeground: '#161616',
        accentColor: '#aee7f7',
      })}>
        <DreamMuseumsSignature />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

const DreamMuseumsSignature = () => {
  const { isConnected } = useConnect();

  const { data } = useAccount()

  publicAddress = ''

  if (data) {
    publicAddress = data.address;
  }

  if (document.getElementById('user_name') && publicAddress.length > 0) {
    message = publicAddress + " is " + document.querySelector('#user_name').textContent
  }

  if (!isConnected) {
    return <ConnectButton label="Add more wallets" showBalance={false} />;
  }

  return (
    <>
      <div className="my-1">
        <ConnectButton label="Add more wallets" showBalance={false} />
      </div>
      <div className="my-1">
        <SignMessage />
      </div>
    </>
  );
};

const SignMessage = () => {

  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
    message: message,
    onSuccess(data) {
      signature = data;
      publicAddress = ethers.utils.verifyMessage(message, signature)
      sendSignedMessage(message, signature, publicAddress);
    },
  })

  return (
    <div>
      <button disabled={isLoading} onClick={() => signMessage()} className="btn-primary hover:scale-105 hover">
        Sign address
      </button>
      {isError && <div className='text-dream-museums-blue'>Error signing message</div>}
    </div>
  )
}

function sendSignedMessage(message, signature, publicAddress) {
  return fetch('/api/sign', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message, signature, public_address: publicAddress }),
  }).then(() => {
    window.location.reload();
  })
}

export default DreamMuseumsSignatureApp;