import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

class HomeCarousel extends Component {
  render() {
    return (
      <Carousel swipeScrollTolerance={1} emulateTouch={true} showArrows={false} showStatus={false} showThumbs={false} showIndicators={true} axis="vertical" verticalSwipe={'standard'} autoPlay={true} interval={5000}>
        <div>
          <img src="home/wwtd.png" />
        </div>
        <div>
          <img src="home/museum.png" />
        </div>
        <div>
          <img src="logo/1024.png" />
        </div>
      </Carousel>
    );
  }
};

export default HomeCarousel