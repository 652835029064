import ClipboardJS from 'clipboard';

const CopyToClipboard = () => {
  var clipboard = new ClipboardJS('.copy-btn');

  clipboard.on('success', function (e) {
    var successMessage = e.trigger.dataset.success
    if (successMessage) {
      let oldtext = e.trigger.textContent
      e.trigger.textContent = successMessage
      console.log(e.trigger.dataset.skipTimeout)
      if (!e.trigger.dataset.skipTimeout) {
        setTimeout(() => e.trigger.textContent = oldtext, 2000)
      }
    }

    e.clearSelection();
  });

  clipboard.on('error', function (e) {
  });
}

export default CopyToClipboard;