const DarkModeToggle = () => {
  function showMainContent() {
    document.getElementById('main-content').style.visibility = 'visible';
    document.getElementById('main-content').style.opacity = 1;
  }

  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  showMainContent()

  let darkModeToggle = document.getElementById('dark-mode-toggle');

  darkModeToggle.onclick = function () {
    if (localStorage.theme === 'dark') {
      document.documentElement.classList.remove('dark')
      localStorage.theme = 'light'
    } else {
      document.documentElement.classList.add('dark')
      localStorage.theme = 'dark'
    }
  }
}

export default DarkModeToggle;